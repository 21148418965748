import PropTypes from 'prop-types';
import React from 'react';

import { root } from './index.module.scss';

const ContentPage = ({ children }) => <div className={root}>{children}</div>;

ContentPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentPage;
