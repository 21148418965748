const buildImageObj = (source) => {
  const imageObj = {
    // eslint-disable-next-line no-underscore-dangle
    asset: { _ref: source.asset._ref || source.asset._id },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
};

export default buildImageObj;
