import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Layout from '../../components/layout';

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
  }
`;

const LayoutContainer = (props) => {
  const [showNav, setShowNav] = useState(false);
  const { menuItems } = props;

  const handleShowNav = () => {
    setShowNav(true);
    console.log('clicked show');
  };

  const handleHideNav = () => {
    setShowNav(false);
    console.log('clicked hide');
  };

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data',
          );
        }
        return (
          <Layout
            {...props}
            menuItems={menuItems}
            showNav={showNav}
            siteTitle={data.site.title}
            onHideNav={handleHideNav}
            onShowNav={handleShowNav}
          />
        );
      }}
    />
  );
};

LayoutContainer.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default LayoutContainer;
