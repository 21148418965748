import PropTypes from 'prop-types';
import React from 'react';

import { root } from './index.module.scss';

const PageTitle = ({ title }) => (
  <h1 className={root}>
    <span>{title}</span>
  </h1>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageTitle;
